import React from "react"
import "./ActionButton.css"

const ActionButton = ({
  className,
  icon,
  alt,
  text,
  color,
  border,
  action,
}) => {
  return (
    <button
      className={`action-button ${className}`}
      style={{
        backgroundColor: color,
        border: border,
        fontSize: text.length < 15 ? "1rem" : "0.75rem",
      }}
      onClick={action}
    >
      <img src={icon} alt={alt} />
      <span>{text}</span>
    </button>
  )
}

export default ActionButton
