import React from "react"
import Layout from "../components/layout"
import { graphql, navigate } from "gatsby"
import buttonIcon from "../images/phone.svg"
import Img from "gatsby-image"
import Content, { HTMLContent } from "../components/Content"
import ActionButton from "../components/ActionButton/ActionButton"
import "./landing.css"
import Anchor from "../components/anchor"

export const LandingPageTemplate = ({
  header,
  paragraph,
  image,
  telephone,
  contentComponent,
  preview,
}) => {
  const PageContent = contentComponent || Content

  return (
    <div className="landing-container">
      {preview ? (
        <img className="landing-image" src={image} alt="strona główna" />
      ) : (
        <Img className="landing-image" fluid={image} />
      )}
      <section className="landing-section">
        <h2 className="landing-header">{header}</h2>
        <div className="action-button__container">
          <Anchor anchor={`tel:+48${telephone}`}>
            <ActionButton
              icon={buttonIcon}
              alt="telefon"
              text="zadzwoń!"
              border="1px solid var(--bg-color)"
              color="var(--pink-color)"
            />
          </Anchor>
          <ActionButton
            icon={buttonIcon}
            alt="kontakt"
            text="kontakt"
            border="1px solid var(--bg-color)"
            color="var(--pink-color)"
            action={() => navigate("/kontakt")}
          />
        </div>
        <PageContent className="landing-content" content={paragraph} />
      </section>
    </div>
  )
}

const LandingPage = ({ data, location }) => {
  const { html, frontmatter } = data.markdownRemark
  const { header, image } = frontmatter
  const { telephone } = data.contactMarkdown.frontmatter

  return (
    <Layout location={location} seoTitle="Strona Główna">
      <LandingPageTemplate
        header={header}
        paragraph={html}
        image={image.childImageSharp.fluid}
        contentComponent={HTMLContent}
        preview={false}
        telephone={telephone}
      />
    </Layout>
  )
}

export default LandingPage

export const LandingPageQuery = graphql`
  query LandingPage($id: String!) {
    contactMarkdown: markdownRemark(
      frontmatter: { template: { eq: "contact" } }
    ) {
      frontmatter {
        telephone
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header
        image {
          childImageSharp {
            fluid(maxWidth: 720, quality: 70) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      html
    }
  }
`
